<template>
    <div id="aboutus">
        <div class="sub-vis main-banner"><span>COMPANY</span></div>

        <div class="container breadcrumbs">
            <router-link to="/"><i class="fal fa-home"></i></router-link> > 
            <span>회사소개</span>
        </div>
        
        <div id="content">
            <div id="aboutus">
                <div class="checker">
                    <dl v-for="(item, index) in company" :key="index">
                        <dt :style="`background-image: url(${AWSURL + item.img});`"></dt>
                        <dd>
                            <strong>{{item.title}}</strong><br class="__pc">
                            {{item.content}}
                        </dd>
                    </dl>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    created(){
        var body =document.querySelector('body')
        body.id = "sub"
    },
    destroyed(){
        var body =document.querySelector('body')
        body.id = ""
    },
    data() {
        return {
            AWSURL: this.$store.state.AWS.URL,
            company: []
        }
    },
    mounted() {
        this.get_company();
    },
    methods: {        
        get_company(){
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/company/about`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        console.log(res.data)
                        this.company = res.data.info;
                    }
                }
            )            
        }
    },
}
</script>

<style scoped>
#content {
    position: relative;
    width: 1420px;
    margin: 0 auto;
    padding: 100px 0;
}
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}
.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}
.fulldiv{
    background-image: url('../../assets/images/banner/main_image_3.jpg');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}
#aboutus .checker>dl>dt {
    position: absolute;
    top: 0;
}
#aboutus .checker>dl:nth-child(odd)>dd {
    margin-left: 50%;
    padding-left: 110px;
}

#aboutus .checker>dl:nth-child(even)>dd {
    margin-right: 50%;
    padding-left: 195px;
    padding-left: 50px;
}
@media screen and (max-width: 1200px) {
    #aboutus .checker>dl>dt {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 50%;
    }
    #aboutus .checker>dl>dd {
        margin: 30px 0;
    }

    #aboutus .checker>dl:nth-child(even)>dd{
        margin-right: 0;
    }
}
@media screen and (max-width: 768px) {
    #aboutus .checker>dl:nth-child(odd)>dd {
        margin-left: 0;
        padding-left: 0;
    }

    #aboutus .checker>dl:nth-child(even)>dd {
        margin-right: 0;
        padding-left: 0;
    }
}
</style>