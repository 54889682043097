<template>
  <section id="sub">
        <div class="sub-vis main-banner"></div>
        <div class="sub-tit portfolio-wrap">
            <hgroup>
                <h3>COMPANY</h3>
                <span></span>
            </hgroup>
            <ul class="tab-tab">
                <li tab="0" class="">
                    <router-link to="/company/aboutus">회사소개</router-link>
                </li>
                <li tab="1" class="active">                    
                    <router-link to="/company/ceo">CEO 인사말</router-link>
                </li>
            </ul>
        </div>
        <div id="content">
            <div id="ceo">
                <div class="lef">
                <img src="https://via.placeholder.com/700x546?text=IMG" alt="">
                </div>
                <div class="rig">
                <strong>행복한 공간을 만들어 드립니다.</strong>
                <p>
                    (주)아이칸은 구성원이 행복해야 고객을 행복하게 만들 수 있다는 모토로 <br class="__pc">
                    20여년간 행복한 공간 만들기에 앞장서고 있습니다.
                    <br><br>
                    시대에 따라 변화하는 Life Style과 고객의 요구에 맞춰 최상의 시공 품질을 제공하며, <br class="__pc">
                    예술적 가치를 지닌 공간을 제안할 수 있는 업체로 자리매김 하겠습니다.
                </p>
                <em><span>아이칸 대표이사</span>이 대 재</em>
            </div>
        </div>




    </div>
</section>
</template>

<script>
export default {

}
</script>

<style>
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}
</style>